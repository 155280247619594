<div (click)="$event.stopPropagation()" class="task-list-date-due-date">
  <div
    *ngIf="!isSubTask && isPrimaryTask && !isRecurringParantId && !serviceRecurring"
    [ngClass]="{ 'disable-freq': isRecurringOn }"
  >
    <div class="d-flex my-3 mx-3">
      <span>Recurring Tasks</span>
      <mat-slide-toggle
        class="ms-3 justify-content-end"
        [(ngModel)]="isTaskRecurring"
        (change)="onChangetoggle($event)"
      >
      </mat-slide-toggle>
    </div>
    <div
      *ngIf="isTaskRecurring"
      [ngClass]="{ 'disable-freq': isRecurringOn }"
      class="mx-3"
    >
      <div class="my-3">
        <mat-select
          name="frequency range"
          [(value)]="selectedFrequency"
          (selectionChange)="selectFrequency($event)"
          placeholder="Select frequency"
        >
          <mat-option *ngFor="let ele of frequency" [value]="ele.value">
            {{ ele.name }}
          </mat-option>
        </mat-select>
        <div class="error-validation" *ngIf="!selectedFrequency">
          Please select frequency
        </div>
      </div>
      <div *ngIf="selectedFrequency === frequencyConst.WEEKLY">
        <ul class="list-unstyled list-inline">
          <li class="list-inline-item me-3" *ngFor="let days of weekDays">
            <mat-checkbox
              class="c-text-checkbox"
              [(ngModel)]="days.isCheck"
              (ngModelChange)="selectWeekDays($event, days)"
            >
              {{ days.sortName }}
            </mat-checkbox>
          </li>
        </ul>
        <div class="error-validation" *ngIf="selectedWeekDays.length == 0">
          Please select day
        </div>
      </div>
      <div *ngIf="selectedFrequency === frequencyConst.MONTHLY">
        <mat-calendar
          [minDate]="rmin ? rmin : todayDate"
          [maxDate]="rmax"
          [(selected)]="monthlyRecurringDate"
          (selectedChange)="monthlyReccuringDate($event)"
        ></mat-calendar>
        <div [ngStyle]="{ 'font-size': '12px' }">
          {{ selectedOnDay | date : "MMM d, y" }}
        </div>
        <div class="error-validation" *ngIf="!selectedOnDay">
          Please select date
        </div>
      </div>

      <div *ngIf="selectedFrequency === frequencyConst.CUSTOM">
        <input
          matInput
          type="number"
          [min]="1"
          [max]="10000"
          placeholder="Enter Day"
          [(ngModel)]="customOnDay"
          (ngModelChange)="customSelectedDay($event)"
        />
        <div class="error-validation" *ngIf="!customOnDay">
          Please select day
        </div>
        <div
          class="error-validation"
          *ngIf="customOnDay > 10000 || customOnDay < 0"
        >
          Please enter day between 1 to 10000
        </div>
      </div>

      <div
        *ngIf="
          selectedFrequency === frequencyConst.QUARTERLY ||
          selectedFrequency === frequencyConst.HALF_YEARLY ||
          selectedFrequency === frequencyConst.YEARLY
        "
      >
        <mat-calendar
          [minDate]="rmin ? rmin : todayDate"
          [maxDate]="rmax"
          [(selected)]="recurringDateSelection"
          (selectedChange)="recurringFrequencyDate($event)"
        ></mat-calendar>
        <div [ngStyle]="{ 'font-size': '12px' }">
          {{ recurringDate | date : "MMM d, y" }}
        </div>
        <div class="error-validation" *ngIf="recurringDate === null">
          Please select date
        </div>
      </div>
    </div>
  </div>
  <span class="my-3 mx-3">Select Due Date</span>
  <div *ngIf="!isTimeSheetSetting" class="my-3 mx-3">
    <mat-calendar
      [minDate]="minDateProject ? minDateProject : todayDate"
      [maxDate]="maxDateProject"
      [(selected)]="selectedDueDate"
      (selectedChange)="taskDueDate($event)"
    ></mat-calendar>
    <div class="text-size-change">
      {{ date | date : "MMM d, y" }}
    </div>
  </div>
  <div *ngIf="isTimeSheetSetting" class="my-3">
    <mat-calendar
      [minDate]="minDateProject ? minDateProject : todayDate"
      [maxDate]="maxDateProject"
      [(selected)]="dateRange"
      (selectedChange)="rangeTaskDueDate($event)"
    ></mat-calendar>
    <div class="text-size-change">
      <span>{{ startDate | date : "MMM d, y" }}</span>
      <span class="mx-3" *ngIf="startDate && endDate">-</span>
      <span>{{ endDate | date : "MMM d, y" }}</span>
    </div>
    <div class="d-flex" [ngClass]="{ 'disable-freq': endDate==null }">
      <div>
        <mat-form-field 
          appearance="outline"
          style="transform: scale(0.75); width: 140px"
        >
          <label for="" class="mt-0 typo-text w-100">
            <span class="timer-block">
              <mat-label>START TIME</mat-label>
            </span>
          </label>
          <input
            type="time"
            matInput
            name="week"
            [(ngModel)]="startTime"
            (ngModelChange)="onStartEndTimeChange(); setStartTime()"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field
          appearance="outline"
          style="transform: scale(0.75); width: 140px"
        >
          <label for="" class="mt-0 typo-text w-100">
            <span class="timer-block">
              <mat-label>END TIME</mat-label>
            </span>
          </label>
          <input
            type="time"
            matInput
            name="week"
            [(ngModel)]="endTime"
            (ngModelChange)="onStartEndTimeChange(); setEndTime()"
          />
        </mat-form-field>
      </div>
    </div>
    <div
      class="d-flex justify-content-center"
      *ngIf="!isSubTask && isPrimaryTask" [ngClass]="{'disable-freq': endDate==null }"
    >
      <span>Estimated Hours</span>
      <input
        type="number"
        matInput
        class="est-hours"
        [(ngModel)]="estHour"
        (ngModelChange)="onEstHourMinChange()"
      />
      <span>:</span>
      <input
        type="number"
        matInput
        class="est-hours"
        [(ngModel)]="estMin"
        (ngModelChange)="onEstHourMinChange()"
      />
    </div>
  </div>
</div>
